import React, { Fragment, } from 'react';
import PropTypes from 'prop-types';
import { useFela, } from 'react-fela';
import { parseTypographyProp, } from '@haaretz/htz-css-tools';

import { useMutation, } from 'react-apollo';
import { UPDATE_ARTICLE_SECTION_MUTATION, } from '@haaretz/graphql';
import HtzLink from '../HtzLink/HtzLink';

import useBreadcrumbsData from '../../hooks/Page/useBreadcrumbsData';
import useArticleType from '../../hooks/Page/useArticleType';


import IconRamatGan from '../Icon/icons/IconRamatGan';
import IconBeerSheva from '../Icon/icons/IconBeerSheva';
import IconJerusalem from '../Icon/icons/IconJerusalem';
import IconPetachTikva from '../Icon/icons/IconPetachTikva';
import IconGaniYoshua from '../Icon/icons/IconGaniYoshua';
import useWebViewChecker from '../../hooks/useWebViewChecker';

const labelsSectionsIndex = {
  2.16463: 'IconRamatGan',
  2.16464: 'IconBeerSheva',
  2.16455: 'IconJerusalem',
  2.16457: 'IconPetachTikva',
  2.16465: 'IconGaniYoshua',
};

const labelsSections = Object.keys(labelsSectionsIndex);

const propTypes = {
  className: PropTypes.string,
  updateArticleSection: PropTypes.func.isRequired,
  crumbs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const defaultProps = {
  className: null,
};

CityIcon.propTypes = {
  icon: PropTypes.string.isRequired,
};

function CityIcon({ icon, ...props }) {
  switch (icon) {
    case 'IconRamatGan':
      return <IconRamatGan {...props} />;
    case 'IconBeerSheva':
      return <IconBeerSheva {...props} />;
    case 'IconJerusalem':
      return <IconJerusalem {...props} />;
    case 'IconPetachTikva':
      return <IconPetachTikva {...props} />;
    case 'IconGaniYoshua':
      return <IconGaniYoshua {...props} />;
    default:
      return <IconGaniYoshua {...props} />;
  }
}

function addMousePostFix(crumbs) {
  const newItems = [ ...crumbs, ];
  const pop = { ...newItems.pop(), };
  pop.name += '(הארץ Labels)';
  newItems.push(pop);
  return newItems;
}

// eslint-disable-next-line react/prop-types
const ColoredLink = ({ crumb, }) => {
  const { css, theme, } = useFela();
  const className = css({
    fontWeight: '700',
    // marginInlineEnd: '1rem',
    ':hover': {
      textDecoration: 'underline',
      underlineSkip: 'ink',
    },
    extend: [
      parseTypographyProp(theme.breadCrumbsStyle.typeScale, theme.type),
    ],
  });

  return (
    /* eslint-disable react/prop-types */
    <HtzLink
      key={crumb.contentId}
      className={className}
      content={theme.breadCrumbsStyle.nameMapping[crumb.name] || crumb.name}
      href={crumb.url}
    />
    /* eslint-enable react/prop-types */
  );
};

function Breadcrumbs({ crumbs, updateArticleSection, className, }) {
  const { css, theme, } = useFela();
  const isWebView = useWebViewChecker();
  React.useEffect(() => {
    const variables = {
      name: null,
      id: null,
      url: null,
    };
    const length = crumbs.length;
    if (length > 0) {
      // eslint-disable-next-line react/prop-types
      variables.name = crumbs[length - 1].name;
      // eslint-disable-next-line react/prop-types
      variables.id = crumbs[length - 1].contentId;
      // eslint-disable-next-line react/prop-types
      variables.url = crumbs[length - 1].url;
    }
    updateArticleSection({
      variables,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ updateArticleSection, ]);

  // exit early if no breadcrumbs
  if (!crumbs.length) return null;

  const lastCrumb = crumbs.slice(-1)[0] || '';
  const isLastOfLabelSection = labelsSections.includes(lastCrumb.contentId);
  const updatedCrumbs = isLastOfLabelSection ? addMousePostFix(crumbs) : crumbs;
  const crumbLinks = updatedCrumbs.map(crumb => <ColoredLink crumb={crumb} />);

  const cityIconWrapper = isLastOfLabelSection ? (
    <span className={css({ float: 'left', })}>
      <CityIcon icon={labelsSectionsIndex[lastCrumb.contentId]} height="4.5rem" />
    </span>
  ) : null;

  return (
    <nav aria-label={theme.breadcrumbsI18n.ariaLabel} className={className}>
      {crumbLinks.map((elem, index) => {
        const isLast = crumbLinks.length - 1 === index;
        const isFirst = index === 0;
        return (
          <Fragment key={elem.props.crumb.contentId + elem.props.crumb.pathSegment}>
            <span
              className={css({
                fontFamily: theme.fontStacks[theme.framedFont],
                color: isFirst
                  ? theme.color('breadCrumbs', 'first')
                  : theme.color('breadCrumbs', 'rest'),
                ':hover': {
                  color: isFirst
                    ? theme.color('breadCrumbs', 'firstHover')
                    : theme.color('breadCrumbs', 'restHover'),
                },

                extend: [
                  theme.mq(
                    { until: 's', },
                    isLast ? { color: theme.color('breadCrumbs', isWebView ? 'firstWebView' : 'first'), } : { display: 'none', }
                  ),
                ],
              })}
            >
              {elem}
              {isLast ? null : (
                <span
                  className={css({
                    extend: [ theme.mq({ until: 's', }, { display: 'none', }), ],
                  })}
                >
                  {' | '}
                </span>
              )}
            </span>
          </Fragment>
        );
      })}
      {cityIconWrapper}
    </nav>
  );
}

Breadcrumbs.propTypes = propTypes;
Breadcrumbs.defaultProps = defaultProps;

// eslint-disable-next-line react/prop-types
export default props => {
  const isWebView = useWebViewChecker();
  const breadcrumbs = useBreadcrumbsData();
  const articleType = useArticleType();

  const [ updateArticleSection, ] = useMutation(UPDATE_ARTICLE_SECTION_MUTATION);

  const isPodcastArticle = articleType === 'podcastArticle';

  if (isWebView && !isPodcastArticle) {
    return null;
  }

  if (!Array.isArray(breadcrumbs) || !breadcrumbs?.length) return null;

  const crumbs = breadcrumbs
    .filter(el => !(el.url || '').includes('ty-article'))
    // homepage -> section -> subsection instead of subsection -> section -> home
    .reverse()
    // Keep only last two subsections
    .slice(-2);

  return (
    <Breadcrumbs
      crumbs={crumbs}
      {...props}
      updateArticleSection={updateArticleSection}
    />
  );
};
